<script setup lang="ts">
	const localePath = useLocalePath()
	const props = defineProps({
			theme: { 
					type: String, 
					required: true 
			},
	});     
</script>
<template>
    <div :class="{'dark': props.theme === 'dark' }" class="flex flex-col md:flex-row max-w-xs md:max-w-none p-10 md:p-0" q>
        <NuxtLink exact-active-class="nav-item-active" class="nav-item" :to="localePath('/')">{{$t('nav.home.title')}}</NuxtLink>
        <NuxtLink exact-active-class="nav-item-active" class="nav-item" :to="localePath('for-work')">{{$t('nav.for-work.title')}}</NuxtLink>
        <NuxtLink exact-active-class="nav-item-active" class="nav-item" :to="localePath('science')">{{$t('nav.science.title')}}</NuxtLink>
        <NuxtLink exact-active-class="nav-item-active" class="nav-item" :to="localePath('blog')">{{$t('nav.blog.title')}}</NuxtLink>
        <NuxtLink exact-active-class="nav-item-active" class="nav-item" rel="help" :to="localePath('help')">{{$t('nav.help.title')}}</NuxtLink>
    </div>
</template>