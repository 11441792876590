import VueGtag from 'vue-gtag-next'

export default defineNuxtPlugin((nuxtApp) => {

	const cookie_control_consent = useCookie('cookie_control_consent')
	console.log('cookie_control_consent', cookie_control_consent.value)

	const cookie_control_enabled_cookies = useCookie('cookie_control_enabled_cookies')
	console.log('cookie_control_enabled_cookies', cookie_control_enabled_cookies.value)

	const enabledList = cookie_control_enabled_cookies.value?.split(',') ?? []
	const gaEnbaled = enabledList?.filter(x => x==='ga').length > 0
	console.log("gaEnbaled", gaEnbaled)

  nuxtApp.vueApp.use(VueGtag, {
    property: {
      id: 'G-CQX647NTZH'
    },
		appName: 'smoxy web',
		enabled: gaEnbaled,
		pageTrackerScreenviewEnabled: true
  }, nuxtApp.$router)
})