import { getApps, initializeApp } from "@firebase/app"
import { getAuth } from "@firebase/auth";

export default defineNuxtPlugin((nuxtApp) => {

	const firebaseConfig = {
		apiKey: "AIzaSyCazlb6nXqxzn2RB5x_5AJfZwXxHIIzjDs",
		authDomain: "smoxy-app.firebaseapp.com",
		databaseURL: "https://smoxy-app.firebaseio.com",
		projectId: "smoxy-app",
		storageBucket: "smoxy-app.appspot.com",
		messagingSenderId: "954577077008",
		appId: "1:954577077008:web:3be114e5bda1e8d747db95",
		measurementId: "G-M4M1XZRQ90"
	};

	//const firebaseConfig = { ...useRuntimeConfig().public.firebaseConfig }

  if (!getApps().length) {
		const app = initializeApp(firebaseConfig)
		const auth = getAuth(app)

		nuxtApp.vueApp.provide('auth', auth)
    nuxtApp.provide('auth', auth)
		console.log("Firebase initialized")
		//const firestore = getFirestore(app)
	}

	



  // Code bellow allows to work with local emulators you can delete it if you work without emulators
  /*
	if(!process.dev) return

  const firestoreHost = getFirestoreServer().toJSON()['settings'].host

  if(firestoreHost !== 'localhost:8080') {
    connectFirestoreEmulatorServer(getFirestoreServer(), 'localhost', 8080)
  }

  if (process.client) {
    connectAuthEmulator(getAuth(), "http://localhost:9099")
    connectFirestoreEmulator(getFirestore(), "localhost", 8080)
    //connectFunctionsEmulator(getFunctions(), "localhost", 5001)
  }
	*/
})