<script setup lang="ts">
const {
  cookiesEnabled,
  cookiesEnabledIds,
  isConsentGiven,
  isModalActive,
  moduleOptions
} = useCookieControl()

</script>
<template>
	<!-- :locale="$i18n.locale" -->

		<CookieControl class="overflow-hidden">
<template #bar>
	<p>We use cookies to enhance your browsing experience, serve personalized content, and analyze our traffic. By clicking "Accept All", you consent to our use of cookies. 
		<!-- <strong @click="isModalActive=true" class="text-slate-300 underline">Manage cookies</strong> -->
	</p>
	
</template>

<template #closeModal>
	<button
			class="cookieControl__ModalClose"
			@click="isModalActive = false"
			>X</button>
</template>

<template #modal>
	<h3>Customize Consent Preferences</h3>
	<p class="text-xs pb-4">We use cookies to help you navigate efficiently and perform certain functions. The cookies that are categorized as "Necessary" are stored on your browser as they are essential for enabling the basic functionalities of the site. We also use third-party cookies that help us analyze how you use this website and store your preferences. These cookies will only be stored in your browser with your prior consent. You can choose to enable or disable some or all of these cookies but disabling some of them may affect your browsing experience.</p>
</template>

</CookieControl> 


</template>
<style>


.cookieControl__ControlButton {
	box-shadow: 0 0 0 rgb(0,0,0,.1) !important;
	/* box-shadow: 0 4px 8px rgb(0 0 0 / 17%) !important; */
	transform: scale(.9);
	
}
.cookieControl__BarContainer {
	padding: 12px !important;
}
.cookieControl__BarContainer p {
	padding-left: 0 !important;
}
.cookieControl__Bar p {
	margin: 0 !important;
	font-size: 12px;
}
.cookieControl__Bar--bottom-right {
	bottom: 0px !important;
}
.cookieControl__Bar--bottom-right p {
    max-width: 390px !important;
}
.cookieControl__BarButtons {
	margin-top: 10px !important;
}
.cookieTypeLabel {
	padding-top: 20px !important;
}
.cookieControl__ModalContent ul li {
	margin: 0 !important;
}
.cookieControl__ModalCookieIDs {
	font-size: 11px;
}
.cookieControl__ModalButtons button, .cookieControl__BarButtons button {
	border-radius: 5px;
	font-size: .8rem;
}
.cookieControl__ModalButtons .partial, .cookieControl__BarButtons .acceptNecessary  {
	background-color: white !important;
	box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 1.0);
	color: black !important;
}

/* .cookieControl__Bar button:hover   {
	box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .1) !important;
} */
.cookieControl__BarButtons .manageCookies, .cookieControl__ModalButtons .declineAll {
	background-color: white !important;
	color: black !important;
}
</style>


