<script lang="ts" setup >
import { useAuthStore } from "~/stores/auth";
//import { Slide } from 'vue3-burger-menu'
const localePath = useLocalePath()
defineProps({
	theme: {type: String, required: true} //light/dark
})

const isOpen = ref(false)

const closeMenu = ()=> {
	isOpen.value = false
}
const openMenu = ()=> {
	isOpen.value = true
}

const auth = useAuthStore();

const isAuthenticated = computed({
		get: () => auth.getAuthenticated,
		set: (val) => {
				auth.setAuthenticated(val);
		}
});

</script>
<template>
    <div class="flex flex-row max-w-screen-xl mx-auto pt-2 md:pt-4 lg:pt-6 pb-10 px-6 md:px-10 items-center">

			<NuxtLink :to="localePath('/')" aria-label="Go home" title="Smoxy" class="mr-auto flex flex-row items-center">
				<img
						alt="Smoxy logo"
						width="48" height="48"
						src="@/assets/logo_transparent.svg"
						class="w-12 h-12 mr-3 p-1 rounded-xl" 
						:class="{'bg-primary-500': theme==='light'}"
						/>
				<SmoxyLogoType :class="{'fill-primary-500': theme === 'light', 'fill-white': theme === 'dark'}" />		
			</NuxtLink>

		
       <!-- <Slide noOverlay :burgerIcon="false" :crossIcon="false" width="320" :isOpen="isOpen" @closeMenu="closeMenu" :closeOnNavigation="true"   >
            <Navigation theme="dark" /> 
       </Slide> -->
    
      <Navigation :theme="theme" class="hidden md:block ml-auto" /> 

<!-- activate login later -->
        <!-- <button class="ml-auto md:ml-20 text-white font-bold hidden sm:block" :class="{'text-black': theme==='light'}" @click="isAuthenticated = !isAuthenticated">Login</button> -->

      <div class="block md:hidden">
         <button
            aria-label="menu"
            @click="isOpen=!isOpen"
            class="p-2 ml-6 text-gray-600 transition bg-white/5 rounded hover:text-gray-600/75" >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-7 h-7 stroke-primary-500"
              :class="{'stroke-white': theme === 'dark'}"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="1.5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>

        <div ref="blocker" @click="closeMenu()" class="bg-black/50 opacity-0 w-full z-40 fixed top-0 bottom-0 block md:hidden transition-opacity" :class="{'opacity-100 cursor-pointer': isOpen, 'opacity-0 pointer-events-none': !isOpen}"></div>
        <div class="fixed -left-full top-0 bottom-0 w-3/4 z-50 flex md:hidden transition-all duration-500  flex-row" :class="{'left-0': isOpen, '-left-full': !isOpen}">
            <div class="bg-[#7E1EF8] w-full h-full">
                <p class="text-white/20 font-bold font-owners text-6xl pl-10 pt-20">Menu</p>
                <Navigation theme="dark" /> 
            </div>
           
        </div>
    </div>
</template>

<style>
.bm-menu {
    background-color: #7E1EF8 !important; 
    overflow-x: hidden;
 }
</style>