
<template>
    <svg
    class="w-28 pt-1" 
    width="418" height="110" viewBox="0 0 418 110"  xmlns="http://www.w3.org/2000/svg">
        <path d="M36.8796 94.9419C15.0946 94.9419 0 84.8423 0 72.8833C0 66.9767 4.46639 62.5103 12.0684 62.5103C23.0065 62.5103 24.2826 72.7557 44.0988 72.7557C50.5341 72.7557 54.727 71.4431 54.727 67.633C54.727 63.8229 51.081 62.3828 44.0988 61.8541C36.0293 61.3882 28.0587 59.8431 20.3996 57.26C9.77138 53.614 0.455755 45.848 0.455755 31.5373C0.455755 16.4427 12.1231 0.436584 37.463 0.436584H49.6773C68.3085 0.436584 81.7077 8.69485 81.7077 18.9402C81.7077 24.7192 77.3689 29.9695 68.5819 29.9695C56.8964 29.9695 55.0552 22.6774 41.9294 22.6774C36.4604 22.6774 32.4862 24.2635 32.4862 28.1465C32.4862 32.0295 36.9344 33.5244 42.8409 34.0531C51.0852 34.7212 59.2296 36.3048 67.1236 38.7747C78.0617 42.4207 87.4866 49.1477 87.4866 63.9688C87.4866 81.1781 74.2151 95.0877 50.0601 95.0877L36.8796 94.9419Z" />
        <path d="M110.639 27.3443C117.475 27.3443 121.012 31.7925 121.267 36.2589C123.66 33.4456 126.64 31.1899 129.997 29.6499C133.354 28.11 137.007 27.3231 140.701 27.3443C144.296 27.2644 147.861 28.0158 151.118 29.5399C154.375 31.0641 157.236 33.3198 159.478 36.1312C161.772 33.3793 164.643 31.1656 167.888 29.6471C171.133 28.1286 174.672 27.3424 178.255 27.3443C188.883 27.3443 200.568 34.1624 200.568 51.7545V81.415C200.568 90.5301 195.191 94.9418 188.354 94.9418H184.945C178.109 94.9418 172.731 90.4754 172.731 81.415V58.0621C172.731 53.9786 171.163 51.0983 167.882 51.0983C164.6 51.0983 160.918 54.3797 160.918 62.0364V81.4515C160.918 90.5666 155.54 94.9783 148.704 94.9783H145.295C138.476 94.9783 133.099 90.5119 133.099 81.4515V58.0621C133.099 53.9786 131.513 51.0983 128.231 51.0983C124.95 51.0983 121.267 54.3797 121.267 62.0364V81.4515C121.267 90.5666 115.889 94.9783 109.071 94.9783H105.662C98.8258 94.9783 93.4479 90.5119 93.4479 81.4515V40.8528C93.4479 31.7378 98.8258 27.3443 105.662 27.3443H110.639Z" />
        <path d="M205.801 61.1978C205.801 52.2144 209.369 43.5991 215.721 37.2469C222.074 30.8947 230.689 27.3261 239.672 27.3261C248.656 27.3261 257.271 30.8947 263.623 37.2469C269.975 43.5991 273.544 52.2144 273.544 61.1978C273.544 81.0322 258.304 94.9419 239.672 94.9419C221.041 94.9419 205.801 81.0322 205.801 61.1978ZM246.108 61.1978C246.108 54.5073 243.738 50.442 239.672 50.442C235.607 50.442 233.237 54.5073 233.237 61.1978C233.237 67.8882 235.607 72.1359 239.672 72.1359C243.738 72.1359 246.108 68.0341 246.108 61.1978Z" />
        <path d="M337.058 73.0292C338.838 74.8579 340.034 77.1738 340.497 79.6835C340.959 82.1931 340.666 84.7835 339.655 87.1264C338.643 89.4692 336.959 91.4592 334.816 92.844C332.672 94.2289 330.166 94.9463 327.615 94.9054C321.307 94.9054 316.184 91.2593 312.393 86.7747L306.085 79.4826L298.082 88.1602C294.436 91.9703 290.589 94.8507 285.612 94.8507C284.198 94.8928 282.791 94.6477 281.474 94.1304C280.157 93.613 278.959 92.8341 277.952 91.8407C276.945 90.8473 276.15 89.66 275.615 88.3506C275.08 87.0412 274.815 85.6369 274.838 84.2225C274.849 81.3875 275.915 78.6581 277.828 76.5658L290.954 62.6562L278.484 49.257C276.126 46.811 274.817 43.5397 274.838 40.1419C274.837 38.4223 275.183 36.72 275.856 35.1376C276.529 33.5551 277.515 32.125 278.755 30.9331C279.994 29.7412 281.462 28.8121 283.069 28.2017C284.677 27.5912 286.392 27.312 288.11 27.3808C294.673 27.3808 299.048 30.9174 303.059 35.5114L309.622 43.1316L317.643 34.0165C320.924 30.3705 324.935 27.3261 330.112 27.3261C331.491 27.2642 332.867 27.4834 334.158 27.9703C335.449 28.4572 336.627 29.2016 337.622 30.1583C338.616 31.115 339.405 32.2639 339.941 33.5352C340.477 34.8065 340.749 36.1735 340.74 37.5532C340.742 40.4245 339.662 43.1911 337.714 45.301L324.461 59.4841L337.058 73.0292Z" />
        <path d="M394.501 36.0948C396.999 31.0998 400.663 27.3079 407.354 27.3079C415.229 27.3079 418 32.5582 418 37.2798C417.846 40.4067 416.993 43.4592 415.502 46.2126L395.814 90.0562C389.78 103.31 381.758 110 368.468 110H365.333C353.921 110 349.454 106.463 349.454 99.4994V97.1295C349.454 91.4964 352.207 89.3999 355.489 88.2149C359.135 86.9023 360.356 85.1887 360.356 82.3084C360.061 79.1903 359.169 76.1578 357.731 73.3756L347.358 49.494C344.86 43.8427 343.949 40.8165 343.949 38.3372C343.949 31.6284 349.71 27.3079 356.801 27.3079H358.387C364.549 27.3079 368.232 30.5893 370.857 36.423L381.795 61.3618L394.501 36.0948Z" />
    </svg>
</template>
