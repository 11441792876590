<template>
    <div class="mb-12 lg:mb-32 md:mb-4 h-auto">
        <div class="flex mx-auto lg:max-w-6xl scale-[.7] sm:scale-100">
            <!-- <div class="w-60 aspect-[240/520] relative">
                <div class="bg-gray-100 scale-75 rounded-2xl md:rounded-3xl w-full h-full absolute translate-x-[40%] drop-shadow-2xl transition-all overflow-hidden">
                    <nuxt-img src="/app-screen-health-heart.jpg" format="webp" quality="65" width="240" height="520" loading="lazy" alt="health heart, app screen smoxy" class="w-full h-full" /> 
                </div>
            </div> -->
            <div class="w-60 aspect-[240/520] relative">
                <div class="scale-90 w-full h-full absolute translate-x-[15%] transition-all">
                    <nuxt-img :src="$t('home.teaser.image_nicotine')" format="webp" quality="90" width="240" height="520" alt="nicotine degraded body, smoxy app screen" class="bg-gray-100 device-image" /> 
                </div>
            </div>
            <div class="w-60 aspect-[240/520] relative">
                <div class="w-full h-full absolute z-50 transition-all">
                    <nuxt-img :src="$t('home.teaser.image_dashboard')" format="webp" quality="90" width="240" height="520"  alt="smoxy app screen dashboard" class="bg-white device-image" /> 
                </div>
            </div>
            <div class="w-60 aspect-[240/520] relative">
                <div class="scale-90 w-full h-full absolute -translate-x-[15%] z-40 transition-all">
                    <nuxt-img :src="$t('home.teaser.image_analyse')"  format="webp" quality="90" width="240" height="520"  alt="smoxy app screen analyse" class="bg-gray-100 device-image" /> 
                </div>
            </div>
            <!-- <div class="w-60 aspect-[240/520] relative">
                <div class="bg-gray-100 scale-75 rounded-2xl md:rounded-3xl w-full h-full absolute -translate-x-[40%] z-30 drop-shadow-2xl transition-all overflow-hidden">
                    <nuxt-img src="/app-screen-craving.jpg" format="webp" quality="80" width="240" height="520" loading="lazy" alt="smoxy app screen, craving strength" class="w-full h-full" /> 
                </div>
            </div>      -->
        </div>
        <!-- Store Download -->

        <div class="flex lg:hidden flex-col items-center sm:mt-8 md:mt-12">
            <img src="@/assets/arrow_drawn_3.svg" width="98" height="73" alt="hand drawn arrow" class="w-20 -translate-y-8 sm:-translate-y-6">
            <StoreRating class="mx-auto" />
            <div class="flex gap-2 pt-4 pb-2 pl-4 pr-4 w-auto mx-auto">
                <a href="https://apps.apple.com/us/app/smoxy-quit-smoking/id1511487101" target="_blank" class="border-solid border-2 border-black/25 rounded-lg hover:bg-black/10 hover:border-transparent hover:scale-[.98] transition-all">
                    <img src="@/assets/appstore_smoxy.svg" width="163" height="48" class="opacity-90" alt="Download Smoxy on AppStore">
                </a>
                <a href="https://play.google.com/store/apps/details?id=net.offlinefirst.smoxy" target="_blank" class="border-solid border-2 border-black/25 rounded-lg hover:bg-black/10 hover:border-transparent hover:scale-[.98] transition-all">
                    <img src="@/assets/play_store_smoxy.svg" class="opacity-90" width="163" height="48" alt="Get Smoxy on Play Store">
                </a>
            </div>
        </div>
    </div>
</template>