interface Research {
    successRate: number
    notSmokedFor4Weeks: number
    successRateStrategy: number
    ts: Date
    users: number
}
export const  smoxyResearchData = () => {
    return <Research>{
        successRate: 95.45, // PREMIUM
     //   successRate: 75.58, // ALL
        notSmokedFor4Weeks: 89.20,
        successRateStrategy: 99.39,
        ts: new Date(2024, 9),
        users: 42901
    }
};