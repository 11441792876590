<template>
	<svg width="163" height="48" viewBox="0 0 163 48" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M156.966 48H6.34964C3.24141 48 0.666016 45.4246 0.666016 42.3164V5.68363C0.666016 2.53099 3.19701 0 6.34964 0H156.966C160.074 0 162.738 2.53099 162.738 5.68363V42.3608C162.738 45.469 160.118 48 156.966 48Z" fill="currentColor" fill-opacity="0.01"/>
	<path d="M36.8721 23.7568C36.8277 19.8938 40.0247 17.9844 40.2023 17.8956C38.3818 15.2314 35.5844 14.8762 34.6075 14.8762C32.2541 14.6098 29.9452 16.2971 28.7463 16.2971C27.503 16.2971 25.6824 14.9206 23.6843 14.965C21.1089 15.0094 18.7111 16.4747 17.379 18.7837C14.6704 23.4904 16.6686 30.4173 19.2884 34.236C20.5761 36.101 22.1302 38.1879 24.1283 38.0991C26.0821 38.0103 26.7925 36.8558 29.1459 36.8558C31.4993 36.8558 32.1653 38.0991 34.2079 38.0547C36.2948 38.0103 37.6269 36.1898 38.8702 34.2804C40.3799 32.1491 41.0016 30.0177 41.0016 29.9289C40.9128 29.9289 36.8721 28.3748 36.8721 23.7568Z" fill="currentColor"/>
	<path d="M32.9785 12.3936C34.0442 11.0615 34.7546 9.28538 34.5326 7.46484C33.0229 7.55365 31.1136 8.53052 30.0035 9.81822C29.0266 10.9283 28.1829 12.7932 28.405 14.525C30.1367 14.6582 31.8684 13.6813 32.9785 12.3936Z" fill="currentColor"/>
	<path d="M104.91 36.6738C105.842 35.8302 106.331 34.7201 106.331 33.3436C106.331 32.2335 106.02 31.301 105.398 30.5906C104.777 29.8357 103.755 29.1697 102.379 28.6368C101.269 28.1928 100.514 27.7932 100.07 27.3935C99.5371 26.9939 99.3151 26.4611 99.3151 25.8394C99.3151 25.2622 99.4927 24.7737 99.9367 24.3741C100.425 23.9301 101.136 23.7081 102.024 23.7081C103.178 23.7081 104.199 23.9745 105.087 24.4629L105.709 22.376C104.732 21.8875 103.533 21.6211 102.068 21.6211C100.425 21.6211 99.1375 22.0207 98.1606 22.8644C97.1837 23.708 96.6953 24.7737 96.6953 26.1058C96.6953 28.104 98.0718 29.6581 100.869 30.6794C101.935 31.079 102.645 31.4786 103.089 31.9227C103.533 32.3667 103.755 32.8995 103.755 33.5656C103.755 34.276 103.489 34.8533 102.956 35.2973C102.423 35.7414 101.668 35.9634 100.692 35.9634C99.3595 35.9634 98.1162 35.6081 97.0061 34.9421L96.4288 37.0735C97.4501 37.7395 98.8266 38.0503 100.558 38.0503C102.423 38.0503 103.889 37.6063 104.91 36.6738Z" fill="currentColor"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M62.3271 37.8286H65.0357L59.8849 21.8434H56.6879L51.5371 37.8286H54.2013L55.6222 33.1218H60.8174L62.3271 37.8286ZM59.0413 26.9941L60.3734 31.1681H56.1107L57.4428 26.9941C57.6508 26.2138 57.8132 25.5401 57.9477 24.9821C58.0428 24.5875 58.124 24.2506 58.1976 23.9747H58.242C58.6416 25.5732 58.9081 26.5501 59.0413 26.9941Z" fill="currentColor"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M76.6694 36.5853C77.7351 35.4308 78.2679 33.8767 78.2679 31.9229C78.2679 30.1468 77.8239 28.7703 76.8914 27.7046C75.9589 26.6389 74.8488 26.1061 73.4723 26.1061C71.6962 26.1061 70.4085 26.7722 69.5648 28.1487H69.5205L69.3872 26.3281H67.1227L67.1271 26.4569C67.17 27.6987 67.2115 28.9007 67.2115 30.1024V42.5353H69.7869V36.4521H69.8313C70.4973 37.5622 71.6074 38.095 73.1171 38.095C74.538 38.095 75.7369 37.6066 76.6694 36.5853ZM74.9377 29.2587C75.4261 29.9692 75.6925 30.9017 75.6925 32.0117C75.6925 33.2106 75.3817 34.1875 74.8488 34.9424C74.316 35.6972 73.5611 36.0524 72.5843 36.0524C71.785 36.0524 71.119 35.786 70.5861 35.2088C70.0977 34.6315 69.8313 33.9211 69.8313 33.0774V31.1681C69.8313 30.9461 69.8757 30.6796 69.9645 30.3688C70.1421 29.7028 70.4529 29.1255 70.9858 28.7259C71.5186 28.3263 72.0958 28.1042 72.7175 28.1042C73.65 28.1042 74.4048 28.5039 74.9377 29.2587Z" fill="currentColor"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M91.5445 31.9229C91.5445 33.8767 91.0116 35.4308 89.946 36.5853C89.0135 37.6066 87.8146 38.095 86.3937 38.095C84.884 38.095 83.7739 37.5622 83.1078 36.4521H83.0635V42.5353H80.4881V30.1024C80.4881 28.9005 80.4466 27.6986 80.4037 26.4566L80.3993 26.3281H82.6638L82.797 28.1487H82.8414C83.6851 26.7722 84.9728 26.1061 86.7489 26.1061C88.1254 26.1061 89.2355 26.6389 90.168 27.7046C91.056 28.7703 91.5445 30.1468 91.5445 31.9229ZM88.9247 32.0117C88.9247 30.9017 88.6583 29.9692 88.1698 29.2587C87.637 28.5039 86.8821 28.1042 85.9497 28.1042C85.328 28.1042 84.7508 28.3263 84.2179 28.7259C83.6851 29.1255 83.3743 29.7028 83.1967 30.3688C83.1078 30.6796 83.0635 30.9461 83.0635 31.1681V33.0774C83.0635 33.9211 83.3299 34.6315 83.8183 35.2088C84.3511 35.786 85.0172 36.0524 85.8165 36.0524C86.7933 36.0524 87.5482 35.6972 88.081 34.9424C88.6583 34.1875 88.9247 33.2106 88.9247 32.0117Z" fill="currentColor"/>
	<path d="M111.97 28.2373V33.8321C111.97 35.253 112.458 35.9635 113.48 35.9635C113.924 35.9635 114.323 35.9191 114.634 35.8303L114.723 37.784C114.235 37.9616 113.569 38.0504 112.725 38.0504C111.704 38.0504 110.904 37.7396 110.372 37.118C109.794 36.4963 109.528 35.4751 109.528 34.0097V28.2373H107.841V26.328H109.528V24.1966L112.059 23.4418V26.328H114.901V28.2373H111.97Z" fill="currentColor"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M126.046 36.3188C127.067 35.2087 127.556 33.7434 127.556 31.9672C127.556 30.2355 127.067 28.8146 126.09 27.7489C125.069 26.6388 123.692 26.0616 122.005 26.0616C120.273 26.0616 118.853 26.5944 117.831 27.7489C116.81 28.9034 116.277 30.3687 116.277 32.1449C116.277 33.8766 116.766 35.2975 117.787 36.4076C118.808 37.5177 120.14 38.0949 121.828 38.0949C123.559 38.0949 124.98 37.4733 126.046 36.3188ZM124.225 29.303C124.669 30.1023 124.891 30.9904 124.891 32.056C124.891 33.1217 124.625 34.0098 124.181 34.8091C123.604 35.7415 122.849 36.23 121.872 36.23C120.895 36.23 120.096 35.7859 119.563 34.8535C119.119 34.0542 118.897 33.1661 118.897 32.1005C118.897 30.9904 119.119 30.0579 119.563 29.303C120.096 28.3706 120.895 27.9265 121.916 27.9265C122.938 27.9265 123.692 28.3706 124.225 29.303Z" fill="currentColor"/>
	<path d="M135.859 28.5482C135.593 28.5038 135.326 28.4594 135.06 28.4594C134.172 28.4594 133.461 28.8146 132.973 29.4807C132.529 30.1023 132.351 30.8572 132.351 31.7452V37.7841H129.776L129.82 29.8803C129.82 28.5482 129.776 27.3493 129.731 26.2392H131.952L132.04 28.4594H132.129C132.396 27.7045 132.84 27.0829 133.417 26.6389C133.994 26.2392 134.616 26.0172 135.282 26.0172C135.504 26.0172 135.726 26.0172 135.903 26.0616V28.5482L135.859 28.5482Z" fill="currentColor"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M147.226 32.6777C147.271 32.3669 147.315 31.9672 147.315 31.5232C147.315 30.0579 146.96 28.859 146.338 27.9265C145.494 26.6832 144.207 26.0616 142.564 26.0616C140.877 26.0616 139.5 26.6832 138.523 27.9265C137.591 29.081 137.102 30.5463 137.102 32.2781C137.102 34.0542 137.591 35.4307 138.612 36.4964C139.633 37.5177 141.054 38.0505 142.83 38.0505C144.296 38.0505 145.583 37.8285 146.693 37.34L146.294 35.5639C145.361 35.9191 144.34 36.0968 143.185 36.0968C142.12 36.0968 141.321 35.8303 140.655 35.2975C139.944 34.6758 139.589 33.8322 139.544 32.6777H147.226ZM144.384 28.9034C144.74 29.4363 144.873 30.1023 144.873 30.8572H139.544C139.633 30.0579 139.855 29.3919 140.299 28.859C140.832 28.193 141.498 27.8377 142.342 27.8377C143.274 27.8377 143.94 28.193 144.384 28.9034Z" fill="currentColor"/>
	<path d="M84.7787 7.86328H86.022V16.1223H84.7787V7.86328Z" fill="currentColor"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M59.5576 12.0372C59.5576 13.4581 59.1136 14.5238 58.2699 15.2342C57.4707 15.9003 56.3606 16.2111 54.9397 16.2111C54.2292 16.2111 53.6076 16.1667 53.1191 16.1223V8.3961C53.7852 8.3073 54.4956 8.21849 55.2949 8.21849C56.6714 8.21849 57.6927 8.52931 58.4031 9.10656C59.158 9.81701 59.5576 10.7495 59.5576 12.0372ZM58.2255 12.0372C58.2255 11.1047 58.0035 10.4387 57.5151 9.95022C57.0266 9.46178 56.3162 9.19536 55.3837 9.19536C54.9841 9.19536 54.6289 9.23977 54.3624 9.28417V15.1454C54.4956 15.1898 54.8065 15.1898 55.2061 15.1898C56.183 15.1898 56.8934 14.9234 57.4263 14.3906C57.9591 13.8577 58.2255 13.0585 58.2255 12.0372Z" fill="currentColor"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M66.6178 13.236C66.6178 14.1241 66.3514 14.8345 65.8629 15.3674C65.3301 15.9446 64.6641 16.211 63.776 16.211C62.9323 16.211 62.2663 15.9446 61.7778 15.3674C61.2894 14.8345 61.023 14.1241 61.023 13.2804C61.023 12.3923 61.2894 11.6819 61.7778 11.1046C62.2663 10.5274 62.9767 10.261 63.8204 10.261C64.664 10.261 65.3301 10.5274 65.8185 11.1046C66.3514 11.7263 66.6178 12.3923 66.6178 13.236ZM65.2857 13.2804C65.2857 12.7476 65.1525 12.3035 64.9305 11.9483C64.664 11.5043 64.3088 11.2823 63.8204 11.2823C63.3319 11.2823 62.9323 11.5043 62.6659 11.9483C62.4439 12.3035 62.3107 12.792 62.3107 13.3248C62.3107 13.8576 62.4439 14.3017 62.6659 14.6569C62.9323 15.1009 63.3319 15.323 63.8204 15.323C64.3088 15.323 64.664 15.1009 64.9305 14.6125C65.1969 14.2573 65.2857 13.8132 65.2857 13.2804Z" fill="currentColor"/>
	<path d="M74.2552 16.1222L76.0313 10.483H74.788L74.122 13.1472C73.9443 13.8133 73.8111 14.4349 73.7223 15.0122H73.6779C73.6064 14.5112 73.4771 14.0391 73.3367 13.5261C73.3029 13.4026 73.2684 13.2765 73.2339 13.1472L72.479 10.483H71.4578L70.5843 13.4432C70.3971 14.0771 70.2487 14.5795 70.1701 15.0122H70.1257C69.9924 14.4349 69.8592 13.8133 69.726 13.1916L69.1044 10.483H67.8167L69.504 16.1222H70.6141L71.4134 13.6801C71.591 13.1028 71.7686 12.4812 71.8574 11.8595H71.9018C71.9906 12.4367 72.1682 13.0583 72.3458 13.6799L73.1007 16.1222H74.2552Z" fill="currentColor"/>
	<path d="M81.3152 16.1224V12.8809C81.3152 11.9041 80.9156 11.3712 80.1607 11.3712C79.8055 11.3712 79.4947 11.5044 79.2726 11.7709C79.0506 12.0373 78.9174 12.3481 78.9174 12.7477V16.1224H77.6741V12.0817C77.6741 11.5932 77.6741 11.0604 77.6297 10.4832H78.7398L78.7842 11.3712H78.8286C78.9618 11.1048 79.1838 10.8828 79.4947 10.7052C79.8499 10.4832 80.2051 10.3944 80.6492 10.3944C81.182 10.3944 81.626 10.572 81.9813 10.9272C82.4253 11.3268 82.6473 11.9929 82.6473 12.7921V16.1224H81.3152Z" fill="currentColor"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M93.4373 13.236C93.4373 14.1241 93.1709 14.8345 92.6825 15.3674C92.1497 15.9446 91.4836 16.211 90.5955 16.211C89.7519 16.211 89.0858 15.9446 88.5974 15.3674C88.109 14.8345 87.8425 14.1241 87.8425 13.2804C87.8425 12.3923 88.109 11.6819 88.5974 11.1046C89.0858 10.5274 89.7963 10.261 90.6399 10.261C91.4836 10.261 92.1497 10.5274 92.6381 11.1046C93.1709 11.7263 93.4373 12.3923 93.4373 13.236ZM92.1052 13.2804C92.1052 12.7476 91.972 12.3035 91.75 11.9483C91.4836 11.5043 91.1284 11.2823 90.6399 11.2823C90.1515 11.2823 89.7519 11.5043 89.4855 11.9483C89.2634 12.3035 89.1302 12.792 89.1302 13.3248C89.1302 13.8576 89.2634 14.3017 89.4855 14.6569C89.7519 15.1009 90.1515 15.323 90.6399 15.323C91.1284 15.323 91.4836 15.1009 91.75 14.6125C92.0164 14.2573 92.1052 13.8132 92.1052 13.2804Z" fill="currentColor"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M98.4105 16.1223H99.5206C99.4762 15.8115 99.4318 15.3675 99.5206 14.7458V12.6588C99.5206 11.1047 98.7658 10.3055 97.3005 10.3055C96.5012 10.3055 95.8795 10.4831 95.3467 10.7939L95.6131 11.6376C96.0128 11.3712 96.5012 11.2379 97.0784 11.2379C97.8333 11.2379 98.2329 11.5932 98.2329 12.348V12.4368C97.1672 12.4368 96.368 12.6144 95.7907 12.9697C95.1691 13.3693 94.9027 13.8577 94.9027 14.5682C94.9027 15.0122 95.0359 15.4119 95.3467 15.7227C95.6575 16.0779 96.1016 16.2555 96.6344 16.2555C97.3449 16.2555 97.8777 15.9891 98.2773 15.4563H98.3217L98.4105 16.1223ZM98.2329 13.2805V14.1686L98.1885 14.3906C98.1441 14.657 97.9665 14.879 97.7445 15.0566C97.5225 15.2342 97.256 15.323 96.9896 15.323C96.7232 15.323 96.5456 15.2342 96.368 15.101C96.2348 14.9678 96.146 14.7458 96.146 14.435C96.146 13.6357 96.8564 13.2361 98.2329 13.2805Z" fill="currentColor"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M106.67 16.1223H105.559L105.515 15.2342H105.471C105.115 15.9447 104.494 16.2555 103.65 16.2555C102.984 16.2555 102.407 15.9891 101.963 15.4562C101.519 14.9234 101.297 14.213 101.297 13.3693C101.297 12.4368 101.519 11.7264 102.052 11.1491C102.54 10.6163 103.117 10.3499 103.783 10.3499C104.538 10.3499 105.071 10.6163 105.382 11.1047H105.426V7.86328H106.67V14.6126C106.625 15.1454 106.625 15.6783 106.67 16.1223ZM105.382 13.7245V12.792C105.382 12.6144 105.382 12.4812 105.337 12.3924C105.249 12.0816 105.115 11.8152 104.893 11.6376C104.671 11.4155 104.361 11.3267 104.05 11.3267C103.561 11.3267 103.206 11.5044 102.94 11.904C102.673 12.2592 102.54 12.7476 102.54 13.3249C102.54 13.9021 102.673 14.3462 102.94 14.7014C103.206 15.0566 103.561 15.2786 104.05 15.2786C104.449 15.2786 104.805 15.1454 105.027 14.8346C105.249 14.4794 105.382 14.1686 105.382 13.7245Z" fill="currentColor"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M116.66 15.3674C117.149 14.8345 117.415 14.1241 117.415 13.236C117.415 12.3923 117.193 11.7263 116.616 11.1046C116.127 10.5274 115.461 10.261 114.618 10.261C113.774 10.261 113.064 10.5274 112.575 11.1046C112.087 11.6819 111.82 12.3923 111.82 13.2804C111.82 14.1241 112.087 14.8345 112.575 15.3674C113.064 15.9446 113.73 16.211 114.573 16.211C115.461 16.211 116.127 15.9446 116.66 15.3674ZM115.772 11.9483C115.994 12.3035 116.127 12.7476 116.127 13.2804C116.127 13.8132 115.994 14.2573 115.772 14.6125C115.506 15.1009 115.151 15.323 114.662 15.323C114.174 15.323 113.774 15.1009 113.508 14.6569C113.286 14.3017 113.152 13.8576 113.152 13.3248C113.152 12.792 113.286 12.3035 113.508 11.9483C113.774 11.5043 114.174 11.2823 114.662 11.2823C115.151 11.2823 115.506 11.5043 115.772 11.9483Z" fill="currentColor"/>
	<path d="M122.966 16.1224V12.8809C122.966 11.9041 122.566 11.3712 121.811 11.3712C121.456 11.3712 121.145 11.5044 120.923 11.7709C120.701 12.0373 120.568 12.3481 120.568 12.7477V16.1224H119.325V12.0817C119.325 11.5932 119.325 11.0604 119.28 10.4832H120.39L120.435 11.3712H120.479C120.612 11.1048 120.834 10.8828 121.145 10.7052C121.5 10.4832 121.856 10.3944 122.3 10.3944C122.832 10.3944 123.276 10.572 123.632 10.9272C124.076 11.3268 124.298 11.9929 124.298 12.7921V16.1224H122.966Z" fill="currentColor"/>
	<path d="M131.269 11.4155H132.646L132.734 10.5719H131.358V9.15096L130.115 9.50618V10.5275H129.271V11.4599H130.115V14.3017C130.115 15.0122 130.248 15.5006 130.514 15.8115C130.781 16.1223 131.18 16.2555 131.669 16.2555C132.068 16.2555 132.379 16.2111 132.646 16.1223L132.601 15.1898C132.423 15.2342 132.246 15.2342 132.024 15.2342C131.535 15.2342 131.269 14.879 131.269 14.1685V11.4155Z" fill="currentColor"/>
	<path d="M138.063 16.1223V12.9253C138.063 11.904 137.663 11.4155 136.908 11.4155C136.331 11.4155 135.931 11.7264 135.709 12.3036C135.665 12.4368 135.665 12.57 135.665 12.7476V16.1667H134.422V7.86328H135.665V11.2823H135.709C136.109 10.6607 136.686 10.3499 137.397 10.3499C137.929 10.3499 138.329 10.5275 138.684 10.8827C139.128 11.3267 139.306 11.9484 139.306 12.792V16.1223H138.063Z" fill="currentColor"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M146.144 13.5914C146.189 13.4138 146.189 13.2362 146.189 13.0142C146.189 12.3481 146.011 11.7265 145.656 11.2824C145.212 10.7052 144.59 10.3944 143.791 10.3944C142.947 10.3944 142.281 10.6608 141.793 11.2824C141.304 11.8597 141.082 12.5701 141.082 13.4138C141.082 14.3019 141.349 14.9679 141.837 15.5007C142.37 15.9892 143.036 16.2556 143.924 16.2556C144.679 16.2556 145.3 16.1224 145.833 15.9004L145.656 15.0123C145.212 15.1899 144.723 15.2787 144.146 15.2787C143.613 15.2787 143.214 15.1455 142.903 14.8791C142.547 14.5683 142.37 14.1686 142.37 13.5914H146.144ZM144.768 11.7265C144.901 11.9929 144.99 12.3481 144.99 12.7033H142.37C142.414 12.3037 142.503 11.9929 142.725 11.7265C142.991 11.3712 143.347 11.1936 143.746 11.1936C144.235 11.1936 144.546 11.3712 144.768 11.7265Z" fill="currentColor"/>
	</svg>
</template>