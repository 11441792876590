<template>
<svg width="163" height="48" viewBox="0 0 163 48" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path fill-rule="evenodd" clip-rule="evenodd" d="M12.6664 7.55566L12.8477 7.7362C13.7591 7.40804 14.8006 7.53849 15.6146 8.13761L32.8766 17.904L33.1428 18.0513L39.0564 21.3979L39.0578 21.3987C40.0823 21.9749 40.8317 22.8791 40.8317 23.9992C40.8317 25.1179 40.0838 26.0248 39.0611 26.6068L39.0592 26.6079L33.1278 29.9661L33.0791 29.9881L15.6357 39.8572C14.8195 40.458 13.7745 40.5875 12.8612 40.2559L12.6661 40.45L11.8528 39.6401L11.1152 38.9944L11.3154 38.795C11.1012 38.3127 11.0007 37.7831 11.0278 37.2476V10.7584C11.0008 10.2255 11.1002 9.69855 11.3122 9.2181L11.0937 9.0007L11.8998 8.29507C11.9454 8.25514 11.9919 8.21682 12.0393 8.18013L12.6664 7.55566ZM27.5925 22.4206L30.9069 19.0876L15.4838 10.3615L27.5925 22.4206ZM32.8389 20.1775L29.0078 23.9929L32.8537 27.823L38.0719 24.8686C38.7355 24.4907 38.8317 24.1374 38.8317 23.9992C38.8317 23.8627 38.7382 23.5133 38.0766 23.1414L38.074 23.14L32.8389 20.1775ZM26.1691 24.0022L13.0278 10.9252V37.0897L26.1691 24.0022ZM15.4859 37.644L30.9128 28.9159L27.5984 25.5909L15.4859 37.644Z" fill="currentColor"/>
	<path d="M56.2507 14.6868C56.8692 14.0438 57.1958 13.1763 57.1544 12.2868C57.1545 12.1057 57.1384 11.925 57.1062 11.7468H53.5998V12.6108H56.2146C56.1967 13.155 55.9829 13.6746 55.6121 14.0748C54.7862 14.8699 53.5612 15.092 52.5069 14.6379C51.4526 14.1838 50.7762 13.1426 50.7923 11.9988C50.7661 11.2419 51.0507 10.5069 51.5804 9.96352C52.1102 9.4201 52.8394 9.11508 53.5998 9.11879C54.3564 9.07762 55.0884 9.39321 55.576 9.9708L56.2146 9.33479C55.9061 8.98146 55.5179 8.70594 55.0819 8.53079C54.6136 8.3312 54.1092 8.2291 53.5998 8.23079C52.6004 8.20861 51.6364 8.59959 50.9369 9.31079C49.8604 10.39 49.5383 12.0067 50.1196 13.4135C50.701 14.8203 52.0724 15.7429 53.5998 15.7548C54.5957 15.7901 55.5596 15.4018 56.2507 14.6868Z" fill="currentColor"/>
	<path d="M59.4197 9.28679H62.673V8.39879H58.4557V15.5988H62.673V14.7108H59.4197V12.4308H62.3839V11.5668H59.4197V9.28679Z" fill="currentColor"/>
	<path d="M65.6974 15.5988H66.6252V9.28679H68.6977V8.39879H63.6731V9.28679H65.6974V15.5988Z" fill="currentColor"/>
	<path d="M73.1681 8.39879H72.2402V15.5988H73.1681V8.39879Z" fill="currentColor"/>
	<path d="M76.3611 15.5988H77.2889V9.28679H79.3012V8.39879H74.3368V9.28679H76.3611V15.5988Z" fill="currentColor"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M83.41 9.32279C81.9885 10.8213 81.9885 13.1643 83.41 14.6628C84.8764 16.1151 87.2453 16.1151 88.7118 14.6628C90.1393 13.1667 90.1393 10.8189 88.7118 9.32279C88.0105 8.61929 87.0563 8.22363 86.0609 8.22363C85.0655 8.22363 84.1113 8.61929 83.41 9.32279ZM88.0249 14.0628C86.9389 15.1399 85.1829 15.1399 84.0968 14.0628C83.0302 12.8918 83.0302 11.1058 84.0968 9.9348C85.1829 8.85772 86.9389 8.85772 88.0249 9.9348C89.0915 11.1058 89.0915 12.8918 88.0249 14.0628Z" fill="currentColor"/>
	<path d="M92.2181 8.39879H91.0855V15.5988H92.0253V9.73079L95.7004 15.5988H96.6644V8.39879H95.7365V14.0028L92.2181 8.39879Z" fill="currentColor"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M77.0239 31.2147C77.015 28.4012 79.2957 26.1121 82.1207 26.0988C83.489 26.0726 84.8093 26.6008 85.7792 27.5622C86.7492 28.5237 87.2857 29.8361 87.2658 31.1988C87.2658 34.0123 84.9779 36.2944 82.1528 36.2988C79.3277 36.3032 77.0327 34.0283 77.0239 31.2147ZM79.0988 32.5409C79.649 33.688 80.8474 34.3835 82.1207 34.2948C82.92 34.2764 83.6778 33.9372 84.2223 33.3542C84.7668 32.7713 85.0518 31.994 85.0126 31.1988C85.0095 29.9276 84.2264 28.7875 83.0379 28.3237C81.8494 27.86 80.4971 28.1669 79.6277 29.0977C78.7583 30.0284 78.5485 31.3939 79.0988 32.5409Z" fill="currentColor"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M65.7938 31.2068C65.7894 28.3917 68.0761 26.1054 70.9028 26.0988H70.8907C72.259 26.0726 73.5793 26.6008 74.5492 27.5622C75.5192 28.5237 76.0557 29.8361 76.0358 31.1988C76.0358 34.0139 73.7455 36.2966 70.9188 36.2988C68.0921 36.301 65.7983 34.0219 65.7938 31.2068ZM67.8728 32.5501C68.4269 33.6965 69.6288 34.3885 70.9028 34.2948H70.8907C71.6899 34.2764 72.4478 33.9372 72.9923 33.3542C73.5368 32.7713 73.8218 31.994 73.7826 31.1988C73.7791 29.9266 72.9945 28.786 71.8045 28.3232C70.6145 27.8605 69.2614 28.1699 68.3933 29.1032C67.5251 30.0366 67.3187 31.4038 67.8728 32.5501Z" fill="currentColor"/>
	<path d="M57.54 29.8308V27.6708L64.854 27.6108C64.938 28.0579 64.9784 28.512 64.9745 28.9668C65.0312 30.8337 64.3571 32.6495 63.0948 34.0308C61.6521 35.5352 59.6267 36.3447 57.54 36.2508C54.6228 36.3639 51.8773 34.8784 50.3849 32.3796C48.8925 29.8807 48.8925 26.7689 50.3849 24.2701C51.8773 21.7712 54.6228 20.2857 57.54 20.3988C59.5893 20.3811 61.5584 21.1906 62.9984 22.6428L61.4681 24.1668C60.4123 23.1517 58.9953 22.595 57.528 22.6188C54.3337 22.6188 51.7442 25.1977 51.7442 28.3788C51.7442 31.56 54.3337 34.1388 57.528 34.1388C59.03 34.1984 60.4873 33.6231 61.5404 32.5548C62.2391 31.8082 62.6637 30.8482 62.7453 29.8308H57.54Z" fill="currentColor"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M107.75 26.0988C109.747 26.1721 111.492 27.4659 112.136 29.3508L112.389 29.8908L105.533 32.7108C105.962 33.6855 106.947 34.2994 108.015 34.2588C109.046 34.2614 110.003 33.7235 110.533 32.8428L112.28 34.0428C111.329 35.4606 109.727 36.308 108.015 36.2988C106.654 36.3187 105.343 35.7866 104.384 34.8247C103.425 33.8629 102.9 32.554 102.93 31.1988C102.847 29.8746 103.318 28.5752 104.231 27.6091C105.144 26.6429 106.418 26.0962 107.75 26.0988ZM105.857 29.0071C105.354 29.567 105.098 30.305 105.147 31.0548L109.726 29.1588C109.366 28.4516 108.617 28.0267 107.822 28.0788C107.068 28.1127 106.36 28.4473 105.857 29.0071Z" fill="currentColor"/>
	<path d="M101.834 35.9988H99.5803V20.9988H101.834V35.9988Z" fill="currentColor"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M95.8088 27.2388H95.8932V26.3988H97.9657V35.4828C97.9657 39.2508 95.7847 40.7988 93.1459 40.7988C91.1569 40.8107 89.3596 39.619 88.6033 37.7868L90.5674 36.9708C90.9779 38.0401 91.9968 38.756 93.1459 38.7828C94.8328 38.7828 95.8811 37.7388 95.8811 35.7828V35.0508H95.8088C95.1317 35.8287 94.1434 36.2681 93.1098 36.2508C90.3747 36.1187 88.2254 33.8718 88.2254 31.1448C88.2254 28.4178 90.3747 26.1709 93.1098 26.0388C94.1401 26.0375 95.122 26.474 95.8088 27.2388ZM93.3109 34.2945C91.7016 34.1513 90.4678 32.8083 90.4678 31.1988C90.4678 29.5881 91.7035 28.2442 93.3146 28.1028C94.0932 28.1426 94.8226 28.4937 95.3375 29.0766C95.8525 29.6595 96.1093 30.4247 96.0498 31.1988C96.1163 31.9765 95.8602 32.7473 95.3413 33.3322C94.8242 33.9149 94.0909 34.2624 93.3109 34.2945Z" fill="currentColor"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M117.305 35.9988V20.9988H122.703C124.453 20.8736 126.127 21.7326 127.041 23.2248C127.955 24.7171 127.955 26.5925 127.041 28.0848C126.127 29.577 124.453 30.4361 122.703 30.3108H119.558V35.9988H117.305ZM119.546 28.1988H122.691L122.739 28.2348C124.167 28.2348 125.324 27.0824 125.324 25.6608C125.324 24.2392 124.167 23.0868 122.739 23.0868H119.546V28.1988Z" fill="currentColor"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M132.572 28.3308C133.331 26.8393 134.908 25.939 136.584 26.0388L136.644 26.0748C139.211 26.0748 141.211 27.5988 141.211 30.2268V35.9988H139.042V34.7988H138.97C138.343 35.7693 137.234 36.3215 136.078 36.2388C135.14 36.3079 134.214 35.9961 133.51 35.3746C132.807 34.753 132.386 33.8745 132.343 32.9388C132.343 30.7668 134.524 29.5668 136.68 29.5668C137.499 29.5634 138.306 29.7612 139.03 30.1428V29.9868C138.973 29.4103 138.685 28.881 138.232 28.5177C137.779 28.1544 137.198 27.9876 136.62 28.0548C135.778 27.9862 134.975 28.4192 134.572 29.1588L132.572 28.3308ZM134.548 32.9868C134.548 33.8868 135.572 34.2588 136.307 34.2588L136.367 34.2948C137.724 34.2619 138.85 33.2381 139.006 31.8948C138.381 31.5473 137.673 31.3732 136.957 31.3908C135.825 31.3908 134.548 31.7868 134.548 32.9868Z" fill="currentColor"/>
	<path d="M146.537 32.9028L149.115 26.3988H151.622L145.452 40.5468H143.103L145.392 35.4948L141.38 26.3988H143.789L146.464 32.9028H146.537Z" fill="currentColor"/>
	<path d="M131.114 35.9988H128.86V20.9988H131.114V35.9988Z" fill="currentColor"/>
</svg>
</template>