export default defineNuxtPlugin((nuxtApp) => {
     addRouteMiddleware('redirect', (to) => {
      console.log('redirect router::', to.path)
      if (to.path.startsWith('/privacy-')) {
        return navigateTo({ path: '/privacy', force: true, replace: true })
      }

			if (to.path.startsWith('/link')) {
        return navigateTo({ path: '/maintenance', force: true, replace: true })
      }

			if (to.path === '/sitemap-0.xml') {
				// OLD sitemap-0.xml redirect code to '301 Moved Permanently'
				return navigateTo('/sitemap.xml', { redirectCode: 301 })
			}
	
      return
    }, { global: true })
})
  