
<template>
    <div ref="buddy" class="flex flex-col-reverse md:flex-row max-w-screen-lg pt-12 md:pt-20 mx-auto gap-10 items-center px-10 lg:px-6">
        
        <div class="pt-2 md:pt-6 flex-1">
            <p class="text-[#7E1EF8]">{{$t('home.features.buddy.title')}}</p>
            <h3 class="text-3xl pr-4 font-bold font-owners text-[#290B50]">{{$t('home.features.buddy.headline')}}</h3>
            <p class="pt-4">{{$t('home.features.buddy.body')}}</p>
        </div>  

        <div class="flex-1 w-full">
            
             <img alt="Magic stars"
                src="@/assets/magic.svg"
                class="w-38 h-38 mx-auto"
                width="100" height="122"
                />  

            <div class="mx-auto w-48 h-48 relative">
                <div class="w-48 h-48 aspect-square absolute -translate-x-20 ">
                   <nuxt-img 
                        provider="cloudinary"
                        src="/avatar_boy.jpg" quality="90" width="192" height="192" loading="lazy" alt="avatar boy" class="w-full h-full drop-shadow-xl rounded-full bg-primary-500" />     
                </div>
                <div class="w-48 h-48 aspect-square translate-x-20 absolute">
                    <!-- https://res.cloudinary.com/smoxy/image/upload/v1658756929/web/avatar_girl.jpg -->
                    <nuxt-img 
                        provider="cloudinary"
                        src="/avatar_girl.jpg" format="webp" quality="90" width="192" height="192" loading="lazy" alt="avatar boy" class="w-full h-full bg-[#903dfc] rounded-full drop-shadow-xl" />     
                </div>
            </div>
        </div>
        
    </div>
</template>