<script lang="ts" setup>
const url =ref("")
const localePath = useLocalePath()
onMounted(()=> {
	url.value = window.location.href
})
</script>
<template>
<div class="bg-newdark-800 text-white ">
		<Header theme="dark" />
		<div class="mx-auto max-w-screen-md py-32 text-center">
			<h1 class="text-xl font-bold text-red-300 text-center">Error 404</h1>
			<h3 class="text-lg font-light text-center pb-10">The page: <span class="text-white/70 break-words">{{ url}}</span> not exist</h3>
			<NuxtLink class="btn-primary-medium" :to="localePath('/')" >Home</NuxtLink>
		</div>
		<Footer theme="dark" />	
</div>

</template>