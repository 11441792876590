import { default as _404B00KTGNlR5Meta } from "/opt/build/repo/pages/404.vue?macro=true";
import { default as blogajPFud6by9Meta } from "/opt/build/repo/pages/blog.vue?macro=true";
import { default as careerswHPeqhkiyxMeta } from "/opt/build/repo/pages/careers.vue?macro=true";
import { default as contactNfojXvLlAkMeta } from "/opt/build/repo/pages/contact.vue?macro=true";
import { default as data_45deletionOE1JrIkEqUMeta } from "/opt/build/repo/pages/data-deletion.vue?macro=true";
import { default as for_45workMz1x8DOMheMeta } from "/opt/build/repo/pages/for-work.vue?macro=true";
import { default as helpAA37UIVEuEMeta } from "/opt/build/repo/pages/help.vue?macro=true";
import { default as imprintqFImHeYkTxMeta } from "/opt/build/repo/pages/imprint.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as maintenance0n2g3CNJhCMeta } from "/opt/build/repo/pages/maintenance.vue?macro=true";
import { default as manifestosRlVZjtAMvMeta } from "/opt/build/repo/pages/manifesto.vue?macro=true";
import { default as _91id_93Omz7BQDG3rMeta } from "/opt/build/repo/pages/posts/[id].vue?macro=true";
import { default as press1stEwxXIGNMeta } from "/opt/build/repo/pages/press.vue?macro=true";
import { default as privacyjCuzG6sq6pMeta } from "/opt/build/repo/pages/privacy.vue?macro=true";
import { default as quitDscsOBgJtYMeta } from "/opt/build/repo/pages/quit.vue?macro=true";
import { default as scienceGol8GkRScCMeta } from "/opt/build/repo/pages/science.vue?macro=true";
import { default as searchi1lVFWwv2QMeta } from "/opt/build/repo/pages/search.vue?macro=true";
import { default as subscribebcJTGHKFkfMeta } from "/opt/build/repo/pages/subscribe.vue?macro=true";
import { default as success_45delete_45accountboB2wnAwuSMeta } from "/opt/build/repo/pages/success-delete-account.vue?macro=true";
import { default as success_45reset_45passwordFurpbS6qcRMeta } from "/opt/build/repo/pages/success-reset-password.vue?macro=true";
import { default as terms9FLT7o4CI4Meta } from "/opt/build/repo/pages/terms.vue?macro=true";
import { default as component_45stubVlEI75ehPBMeta } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
export default [
  {
    name: _404B00KTGNlR5Meta?.name ?? "404___en",
    path: _404B00KTGNlR5Meta?.path ?? "/404",
    meta: _404B00KTGNlR5Meta || {},
    alias: _404B00KTGNlR5Meta?.alias || [],
    redirect: _404B00KTGNlR5Meta?.redirect,
    component: () => import("/opt/build/repo/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _404B00KTGNlR5Meta?.name ?? "404___de",
    path: _404B00KTGNlR5Meta?.path ?? "/de/404",
    meta: _404B00KTGNlR5Meta || {},
    alias: _404B00KTGNlR5Meta?.alias || [],
    redirect: _404B00KTGNlR5Meta?.redirect,
    component: () => import("/opt/build/repo/pages/404.vue").then(m => m.default || m)
  },
  {
    name: blogajPFud6by9Meta?.name ?? "blog___en",
    path: blogajPFud6by9Meta?.path ?? "/blog",
    meta: blogajPFud6by9Meta || {},
    alias: blogajPFud6by9Meta?.alias || [],
    redirect: blogajPFud6by9Meta?.redirect,
    component: () => import("/opt/build/repo/pages/blog.vue").then(m => m.default || m)
  },
  {
    name: blogajPFud6by9Meta?.name ?? "blog___de",
    path: blogajPFud6by9Meta?.path ?? "/de/blog",
    meta: blogajPFud6by9Meta || {},
    alias: blogajPFud6by9Meta?.alias || [],
    redirect: blogajPFud6by9Meta?.redirect,
    component: () => import("/opt/build/repo/pages/blog.vue").then(m => m.default || m)
  },
  {
    name: careerswHPeqhkiyxMeta?.name ?? "careers___en",
    path: careerswHPeqhkiyxMeta?.path ?? "/careers",
    meta: careerswHPeqhkiyxMeta || {},
    alias: careerswHPeqhkiyxMeta?.alias || [],
    redirect: careerswHPeqhkiyxMeta?.redirect,
    component: () => import("/opt/build/repo/pages/careers.vue").then(m => m.default || m)
  },
  {
    name: careerswHPeqhkiyxMeta?.name ?? "careers___de",
    path: careerswHPeqhkiyxMeta?.path ?? "/de/karriere",
    meta: careerswHPeqhkiyxMeta || {},
    alias: careerswHPeqhkiyxMeta?.alias || [],
    redirect: careerswHPeqhkiyxMeta?.redirect,
    component: () => import("/opt/build/repo/pages/careers.vue").then(m => m.default || m)
  },
  {
    name: contactNfojXvLlAkMeta?.name ?? "contact___en",
    path: contactNfojXvLlAkMeta?.path ?? "/contact",
    meta: contactNfojXvLlAkMeta || {},
    alias: contactNfojXvLlAkMeta?.alias || [],
    redirect: contactNfojXvLlAkMeta?.redirect,
    component: () => import("/opt/build/repo/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactNfojXvLlAkMeta?.name ?? "contact___de",
    path: contactNfojXvLlAkMeta?.path ?? "/de/kontakt",
    meta: contactNfojXvLlAkMeta || {},
    alias: contactNfojXvLlAkMeta?.alias || [],
    redirect: contactNfojXvLlAkMeta?.redirect,
    component: () => import("/opt/build/repo/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: data_45deletionOE1JrIkEqUMeta?.name ?? "data-deletion___en",
    path: data_45deletionOE1JrIkEqUMeta?.path ?? "/data-deletion",
    meta: data_45deletionOE1JrIkEqUMeta || {},
    alias: data_45deletionOE1JrIkEqUMeta?.alias || [],
    redirect: data_45deletionOE1JrIkEqUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/data-deletion.vue").then(m => m.default || m)
  },
  {
    name: data_45deletionOE1JrIkEqUMeta?.name ?? "data-deletion___de",
    path: data_45deletionOE1JrIkEqUMeta?.path ?? "/de/data-deletion",
    meta: data_45deletionOE1JrIkEqUMeta || {},
    alias: data_45deletionOE1JrIkEqUMeta?.alias || [],
    redirect: data_45deletionOE1JrIkEqUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/data-deletion.vue").then(m => m.default || m)
  },
  {
    name: for_45workMz1x8DOMheMeta?.name ?? "for-work___en",
    path: for_45workMz1x8DOMheMeta?.path ?? "/for-work",
    meta: for_45workMz1x8DOMheMeta || {},
    alias: for_45workMz1x8DOMheMeta?.alias || [],
    redirect: for_45workMz1x8DOMheMeta?.redirect,
    component: () => import("/opt/build/repo/pages/for-work.vue").then(m => m.default || m)
  },
  {
    name: for_45workMz1x8DOMheMeta?.name ?? "for-work___de",
    path: for_45workMz1x8DOMheMeta?.path ?? "/de/for-work",
    meta: for_45workMz1x8DOMheMeta || {},
    alias: for_45workMz1x8DOMheMeta?.alias || [],
    redirect: for_45workMz1x8DOMheMeta?.redirect,
    component: () => import("/opt/build/repo/pages/for-work.vue").then(m => m.default || m)
  },
  {
    name: helpAA37UIVEuEMeta?.name ?? "help___en",
    path: helpAA37UIVEuEMeta?.path ?? "/help",
    meta: helpAA37UIVEuEMeta || {},
    alias: helpAA37UIVEuEMeta?.alias || [],
    redirect: helpAA37UIVEuEMeta?.redirect,
    component: () => import("/opt/build/repo/pages/help.vue").then(m => m.default || m)
  },
  {
    name: helpAA37UIVEuEMeta?.name ?? "help___de",
    path: helpAA37UIVEuEMeta?.path ?? "/de/hilfe",
    meta: helpAA37UIVEuEMeta || {},
    alias: helpAA37UIVEuEMeta?.alias || [],
    redirect: helpAA37UIVEuEMeta?.redirect,
    component: () => import("/opt/build/repo/pages/help.vue").then(m => m.default || m)
  },
  {
    name: imprintqFImHeYkTxMeta?.name ?? "imprint___en",
    path: imprintqFImHeYkTxMeta?.path ?? "/imprint",
    meta: imprintqFImHeYkTxMeta || {},
    alias: imprintqFImHeYkTxMeta?.alias || [],
    redirect: imprintqFImHeYkTxMeta?.redirect,
    component: () => import("/opt/build/repo/pages/imprint.vue").then(m => m.default || m)
  },
  {
    name: imprintqFImHeYkTxMeta?.name ?? "imprint___de",
    path: imprintqFImHeYkTxMeta?.path ?? "/de/imprint",
    meta: imprintqFImHeYkTxMeta || {},
    alias: imprintqFImHeYkTxMeta?.alias || [],
    redirect: imprintqFImHeYkTxMeta?.redirect,
    component: () => import("/opt/build/repo/pages/imprint.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___en",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___de",
    path: indexlaFeZuYZkUMeta?.path ?? "/de",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: maintenance0n2g3CNJhCMeta?.name ?? "maintenance___en",
    path: maintenance0n2g3CNJhCMeta?.path ?? "/maintenance",
    meta: maintenance0n2g3CNJhCMeta || {},
    alias: maintenance0n2g3CNJhCMeta?.alias || [],
    redirect: maintenance0n2g3CNJhCMeta?.redirect,
    component: () => import("/opt/build/repo/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: maintenance0n2g3CNJhCMeta?.name ?? "maintenance___de",
    path: maintenance0n2g3CNJhCMeta?.path ?? "/de/maintenance",
    meta: maintenance0n2g3CNJhCMeta || {},
    alias: maintenance0n2g3CNJhCMeta?.alias || [],
    redirect: maintenance0n2g3CNJhCMeta?.redirect,
    component: () => import("/opt/build/repo/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: manifestosRlVZjtAMvMeta?.name ?? "manifesto___en",
    path: manifestosRlVZjtAMvMeta?.path ?? "/manifesto",
    meta: manifestosRlVZjtAMvMeta || {},
    alias: manifestosRlVZjtAMvMeta?.alias || [],
    redirect: manifestosRlVZjtAMvMeta?.redirect,
    component: () => import("/opt/build/repo/pages/manifesto.vue").then(m => m.default || m)
  },
  {
    name: manifestosRlVZjtAMvMeta?.name ?? "manifesto___de",
    path: manifestosRlVZjtAMvMeta?.path ?? "/de/manifest",
    meta: manifestosRlVZjtAMvMeta || {},
    alias: manifestosRlVZjtAMvMeta?.alias || [],
    redirect: manifestosRlVZjtAMvMeta?.redirect,
    component: () => import("/opt/build/repo/pages/manifesto.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Omz7BQDG3rMeta?.name ?? "posts-id___en",
    path: _91id_93Omz7BQDG3rMeta?.path ?? "/posts/:id()",
    meta: _91id_93Omz7BQDG3rMeta || {},
    alias: _91id_93Omz7BQDG3rMeta?.alias || [],
    redirect: _91id_93Omz7BQDG3rMeta?.redirect,
    component: () => import("/opt/build/repo/pages/posts/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Omz7BQDG3rMeta?.name ?? "posts-id___de",
    path: _91id_93Omz7BQDG3rMeta?.path ?? "/de/posts/:id()",
    meta: _91id_93Omz7BQDG3rMeta || {},
    alias: _91id_93Omz7BQDG3rMeta?.alias || [],
    redirect: _91id_93Omz7BQDG3rMeta?.redirect,
    component: () => import("/opt/build/repo/pages/posts/[id].vue").then(m => m.default || m)
  },
  {
    name: press1stEwxXIGNMeta?.name ?? "press___en",
    path: press1stEwxXIGNMeta?.path ?? "/press",
    meta: press1stEwxXIGNMeta || {},
    alias: press1stEwxXIGNMeta?.alias || [],
    redirect: press1stEwxXIGNMeta?.redirect,
    component: () => import("/opt/build/repo/pages/press.vue").then(m => m.default || m)
  },
  {
    name: press1stEwxXIGNMeta?.name ?? "press___de",
    path: press1stEwxXIGNMeta?.path ?? "/de/press",
    meta: press1stEwxXIGNMeta || {},
    alias: press1stEwxXIGNMeta?.alias || [],
    redirect: press1stEwxXIGNMeta?.redirect,
    component: () => import("/opt/build/repo/pages/press.vue").then(m => m.default || m)
  },
  {
    name: privacyjCuzG6sq6pMeta?.name ?? "privacy___en",
    path: privacyjCuzG6sq6pMeta?.path ?? "/privacy",
    meta: privacyjCuzG6sq6pMeta || {},
    alias: privacyjCuzG6sq6pMeta?.alias || [],
    redirect: privacyjCuzG6sq6pMeta?.redirect,
    component: () => import("/opt/build/repo/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyjCuzG6sq6pMeta?.name ?? "privacy___de",
    path: privacyjCuzG6sq6pMeta?.path ?? "/de/privacy",
    meta: privacyjCuzG6sq6pMeta || {},
    alias: privacyjCuzG6sq6pMeta?.alias || [],
    redirect: privacyjCuzG6sq6pMeta?.redirect,
    component: () => import("/opt/build/repo/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: quitDscsOBgJtYMeta?.name ?? "quit___en",
    path: quitDscsOBgJtYMeta?.path ?? "/quit",
    meta: quitDscsOBgJtYMeta || {},
    alias: quitDscsOBgJtYMeta?.alias || [],
    redirect: quitDscsOBgJtYMeta?.redirect,
    component: () => import("/opt/build/repo/pages/quit.vue").then(m => m.default || m)
  },
  {
    name: quitDscsOBgJtYMeta?.name ?? "quit___de",
    path: quitDscsOBgJtYMeta?.path ?? "/de/quit",
    meta: quitDscsOBgJtYMeta || {},
    alias: quitDscsOBgJtYMeta?.alias || [],
    redirect: quitDscsOBgJtYMeta?.redirect,
    component: () => import("/opt/build/repo/pages/quit.vue").then(m => m.default || m)
  },
  {
    name: scienceGol8GkRScCMeta?.name ?? "science___en",
    path: scienceGol8GkRScCMeta?.path ?? "/science",
    meta: scienceGol8GkRScCMeta || {},
    alias: scienceGol8GkRScCMeta?.alias || [],
    redirect: scienceGol8GkRScCMeta?.redirect,
    component: () => import("/opt/build/repo/pages/science.vue").then(m => m.default || m)
  },
  {
    name: scienceGol8GkRScCMeta?.name ?? "science___de",
    path: scienceGol8GkRScCMeta?.path ?? "/de/science",
    meta: scienceGol8GkRScCMeta || {},
    alias: scienceGol8GkRScCMeta?.alias || [],
    redirect: scienceGol8GkRScCMeta?.redirect,
    component: () => import("/opt/build/repo/pages/science.vue").then(m => m.default || m)
  },
  {
    name: searchi1lVFWwv2QMeta?.name ?? "search___en",
    path: searchi1lVFWwv2QMeta?.path ?? "/search",
    meta: searchi1lVFWwv2QMeta || {},
    alias: searchi1lVFWwv2QMeta?.alias || [],
    redirect: searchi1lVFWwv2QMeta?.redirect,
    component: () => import("/opt/build/repo/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchi1lVFWwv2QMeta?.name ?? "search___de",
    path: searchi1lVFWwv2QMeta?.path ?? "/de/search",
    meta: searchi1lVFWwv2QMeta || {},
    alias: searchi1lVFWwv2QMeta?.alias || [],
    redirect: searchi1lVFWwv2QMeta?.redirect,
    component: () => import("/opt/build/repo/pages/search.vue").then(m => m.default || m)
  },
  {
    name: subscribebcJTGHKFkfMeta?.name ?? "subscribe___en",
    path: subscribebcJTGHKFkfMeta?.path ?? "/subscribe",
    meta: subscribebcJTGHKFkfMeta || {},
    alias: subscribebcJTGHKFkfMeta?.alias || [],
    redirect: subscribebcJTGHKFkfMeta?.redirect,
    component: () => import("/opt/build/repo/pages/subscribe.vue").then(m => m.default || m)
  },
  {
    name: subscribebcJTGHKFkfMeta?.name ?? "subscribe___de",
    path: subscribebcJTGHKFkfMeta?.path ?? "/de/subscribe",
    meta: subscribebcJTGHKFkfMeta || {},
    alias: subscribebcJTGHKFkfMeta?.alias || [],
    redirect: subscribebcJTGHKFkfMeta?.redirect,
    component: () => import("/opt/build/repo/pages/subscribe.vue").then(m => m.default || m)
  },
  {
    name: success_45delete_45accountboB2wnAwuSMeta?.name ?? "success-delete-account___en",
    path: success_45delete_45accountboB2wnAwuSMeta?.path ?? "/success-delete-account",
    meta: success_45delete_45accountboB2wnAwuSMeta || {},
    alias: success_45delete_45accountboB2wnAwuSMeta?.alias || [],
    redirect: success_45delete_45accountboB2wnAwuSMeta?.redirect,
    component: () => import("/opt/build/repo/pages/success-delete-account.vue").then(m => m.default || m)
  },
  {
    name: success_45delete_45accountboB2wnAwuSMeta?.name ?? "success-delete-account___de",
    path: success_45delete_45accountboB2wnAwuSMeta?.path ?? "/de/success-delete-account",
    meta: success_45delete_45accountboB2wnAwuSMeta || {},
    alias: success_45delete_45accountboB2wnAwuSMeta?.alias || [],
    redirect: success_45delete_45accountboB2wnAwuSMeta?.redirect,
    component: () => import("/opt/build/repo/pages/success-delete-account.vue").then(m => m.default || m)
  },
  {
    name: success_45reset_45passwordFurpbS6qcRMeta?.name ?? "success-reset-password___en",
    path: success_45reset_45passwordFurpbS6qcRMeta?.path ?? "/success-reset-password",
    meta: success_45reset_45passwordFurpbS6qcRMeta || {},
    alias: success_45reset_45passwordFurpbS6qcRMeta?.alias || [],
    redirect: success_45reset_45passwordFurpbS6qcRMeta?.redirect,
    component: () => import("/opt/build/repo/pages/success-reset-password.vue").then(m => m.default || m)
  },
  {
    name: success_45reset_45passwordFurpbS6qcRMeta?.name ?? "success-reset-password___de",
    path: success_45reset_45passwordFurpbS6qcRMeta?.path ?? "/de/success-reset-password",
    meta: success_45reset_45passwordFurpbS6qcRMeta || {},
    alias: success_45reset_45passwordFurpbS6qcRMeta?.alias || [],
    redirect: success_45reset_45passwordFurpbS6qcRMeta?.redirect,
    component: () => import("/opt/build/repo/pages/success-reset-password.vue").then(m => m.default || m)
  },
  {
    name: terms9FLT7o4CI4Meta?.name ?? "terms___en",
    path: terms9FLT7o4CI4Meta?.path ?? "/terms",
    meta: terms9FLT7o4CI4Meta || {},
    alias: terms9FLT7o4CI4Meta?.alias || [],
    redirect: terms9FLT7o4CI4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: terms9FLT7o4CI4Meta?.name ?? "terms___de",
    path: terms9FLT7o4CI4Meta?.path ?? "/de/terms",
    meta: terms9FLT7o4CI4Meta || {},
    alias: terms9FLT7o4CI4Meta?.alias || [],
    redirect: terms9FLT7o4CI4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/sitemap",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: () => import("/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  }
]