import { ModuleOptions } from '../../src/runtime/types'

export default {
  "barPosition": "bottom-right",
  "colors": {
    "barBackground": "#fff",
    "barButtonBackground": "#000",
    "barButtonColor": "#fff",
    "barButtonHoverBackground": "#D1D1D1",
    "barButtonHoverColor": "#000",
    "barTextColor": "#000",
    "checkboxActiveBackground": "#000",
    "checkboxActiveCircleBackground": "#fff",
    "checkboxDisabledBackground": "#ddd",
    "checkboxDisabledCircleBackground": "#fff",
    "checkboxInactiveBackground": "#000",
    "checkboxInactiveCircleBackground": "#fff",
    "controlButtonBackground": "#fff",
    "controlButtonHoverBackground": "#000",
    "controlButtonIconColor": "#000",
    "controlButtonIconHoverColor": "#fff",
    "modalBackground": "#fff",
    "modalButtonBackground": "#000",
    "modalButtonColor": "#fff",
    "modalButtonHoverBackground": "#333",
    "modalButtonHoverColor": "#fff",
    "modalOverlay": "#000",
    "modalOverlayOpacity": 0.8,
    "modalTextColor": "#000",
    "modalUnsavedColor": "#fff"
  },
  "cookies": {
    "necessary": [
      {
        "description": {
          "en": "Language and other user settings",
          "de": "Sprache und ander Benutzereinstellungen"
        },
        "id": "functional",
        "name": {
          "en": "Functional",
          "de": "Funktional"
        }
      }
    ],
    "optional": [
      {
        "description": {
          "en": "Used for cookie control.",
          "de": "Wird für die Cookie-Kontrolle verwendet."
        },
        "id": "ga",
        "name": {
          "en": "Google Analytics",
          "de": "Google Analytics"
        },
        "src": "https://www.googletagmanager.com/gtag/js?id=G-CQX647NTZH"
      }
    ]
  },
  "isAcceptNecessaryButtonEnabled": true,
  "isControlButtonEnabled": false,
  "isCssEnabled": true,
  "isCssPolyfillEnabled": true,
  "isDashInDescriptionEnabled": true,
  "isIframeBlocked": false,
  "domain": "smoxy.app",
  "locales": [
    "en",
    "en"
  ],
  "localeTexts": {
    "en": {
      "acceptAll": "Accept all",
      "acceptNecessary": "Accept necessary",
      "barDescription": "We use our own cookies and third-party cookies so that we can show you this website and better understand how you use it, with a view to improving the services we offer. If you continue browsing, we consider that you have accepted the cookies.",
      "barTitle": "Cookies",
      "blockedIframe": "To see this, please enable functional cookies",
      "close": "Close",
      "declineAll": "Delete all",
      "functional": "Functional cookies",
      "here": "here",
      "manageCookies": "Manage cookies",
      "necessary": "Necessary cookies",
      "optional": "Optional cookies",
      "none": "none",
      "save": "Save",
      "unsaved": "You have unsaved settings"
    }
  }
} as ModuleOptions