<script lang="ts" setup>
import { smoxyResearchData } from "~~/utils/research"

const props = defineProps({
			theme: { 
					type: String, 
					required: false,
					default: 'light' 
			},
			enSlug: {
				type: String,
				required: false
			},
			deSlug: {
				type: String,
				required: false
			},
	}); 

const textMutedColor = ref(props.theme !== 'light' ? 'text-[#9489a2]' : 'text-gray-400')

const switchLocalePath = useSwitchLocalePath()
const localePath = useLocalePath()
const year = new Date().getFullYear()
const successRate = `${Math.floor(smoxyResearchData().successRate)}%`
const { locale } = useI18n()

console.log('locale lang:', locale.value )
//const sitemapLink =  () => `${locale.value === 'en' ? '' :  locale.value}/sitemap.xml`
const sitemapLink = computed(() => { return locale.value === 'en' ? '/sitemap.xml' : `/${locale.value}/sitemap.xml` })
/*
//const { locale, locales } = useI18n()
const availableLocales = computed(() => {
   return (locales.value as any).filter((i:any) => i.code !== locale.value)
})

console.log('locales::', availableLocales.value)
*/

</script>

<template>
  <!-- <div :class="{'bg-white': theme === 'light', 'bg-newdark-800': theme === 'dark'}"> -->
  <div :class="{'dark': theme === 'dark', 'light': theme === 'light'}" class="light:bg-white dark:bg-newdark-900 mx-auto max-w-screen-xl">
    <!-- sm:max-w-xl md:max-w-full lg:max-w-screen-xl -->
    <div class="pt-16 mx-auto px-6 md:px-10 text-black dark:text-white">
      <div class="grid gap-8 mb-8 lg:grid-cols-6 ">

        <div class="md:max-w-md lg:col-span-2">
					<NuxtLink :to="localePath('/')" aria-label="Go home" title="Smoxy" class="inline-flex items-center">
						<img src="@/assets/logo.svg" width="418" height="418" class="w-10" alt="smoxy logo">
					</NuxtLink>
          <div class="mt-4 lg:max-w-sm">
            <p class="text-sm leading-6 mt-10 mb-8 text-black/40 dark:text-white/50 max-w-sm mr-8">{{$t('footer.quote', {'success_rate': successRate })}}</p>
            <div class="flex gap-2 pt-7 w-auto mx-auto">
              <a href="https://play.google.com/store/apps/details?id=net.offlinefirst.smoxy" target="_blank" aria-label="Get it on Google Play" class="store-logo-link ">
                <DownloadPlayStore class="text-gray-800 dark:text-[#9489a2]" />  
								<!-- <img src="@/assets/play_store_smoxy.svg" class="opacity-90 h-10 my-1" width="163" height="48" alt="Get Smoxy on Play Store"> -->
              </a>
              <a href="https://apps.apple.com/us/app/smoxy-quit-smoking/id1511487101" aria-label="Download on the App Store" target="_blank" class="store-logo-link">
                  <!-- <img src="@/assets/appstore_smoxy.svg" width="163" height="48" class="opacity-90 h-10 my-1" alt="Download Smoxy on AppStore"> -->
								<DownloadAppStore class="text-gray-800 dark:text-[#9489a2]"  />
								</a>
          	</div>
          </div>
        </div>

        <div class="grid grid-cols-2 gap-5 row-gap-8 lg:col-span-4 md:grid-cols-4 gap-y-14  ">

          <!-- <div>
            <p class="font-semibold tracking-wide text-gray-800">Themen</p>
            <ul class="mt-2 space-y-2">
              <li>
                <a href="/" class="nav-link">So gelingt der Rauchstopp</a>
              </li>
              <li>
                <a href="/" class="nav-link">Verlangen nach Zigaretten besiegen</a>
              </li>
              <li>
                <a href="/" class="nav-link">Nachhalktige Suchtbefreiung</a>
              </li>
              <li>
                <a href="/" class="nav-link">Alle Artikel</a>
              </li>
            </ul>
          </div> -->

          <div>
            <p class="tracking-wide pb-4" :class="textMutedColor">App</p>
            <ul class="mt-2 space-y-4">
              <!-- <li><NuxtLink to="/abo" class="nav-link">Abonnieren</NuxtLink></li> -->
							<!-- <li><NuxtLink to="/code" class="nav-link">Code einloesen</NuxtLink></li> -->
              <!-- <li><NuxtLink to="/gift" class="nav-link">Geschenkgutschein</NuxtLink></li> -->
							<li><NuxtLink :to="localePath('data-deletion')" class="nav-link">Data Deletion</NuxtLink></li>
              <li><NuxtLink to="/subscribe" class="nav-link">Subscribe</NuxtLink></li>
              <li><NuxtLink :to="localePath('for-work')" class="nav-link">Smoxy for Work</NuxtLink></li>
              <li><a href="https://portal.smoxy.app/sign-up?redirect=portal" aria-label="Smoxy for Work - Portal login" class="nav-link">For Work login</a></li>
            </ul>
          </div>
          
          <div>
            <p class="tracking-wide pb-4" :class="textMutedColor">Support</p>
            <ul class="mt-2 space-y-4">
              <li><NuxtLink class="nav-link" rel="help" :to="localePath('help')">{{ $t('help.title') }}</NuxtLink></li>
              <li><NuxtLink class="nav-link" :to="localePath('contact')">{{ $t('contact.title') }}</NuxtLink></li>
              <li><NuxtLink class="nav-link" :to="localePath('science')">{{ $t('nav.science.title') }}</NuxtLink></li>
            </ul>
          </div>

          <!-- About Us -->
          <div>
            <p class="tracking-wide pb-4" :class="textMutedColor">{{$t('footer.about_us')}}</p>
            <ul class="mt-2 space-y-4">
              <li><NuxtLink class="nav-link" :to="localePath('manifesto')">{{ $t('nav.manifest.title') }}</NuxtLink></li>
              <li><NuxtLink class="nav-link" :to="localePath('press')">{{ $t('press.title') }}</NuxtLink></li>
              <li><NuxtLink class="nav-link" :to="localePath('careers')">{{ $t('careers.title') }}</NuxtLink></li>
            </ul>
          </div>

          <!-- Misc -->
          <div>
            <p class="tracking-wide pb-4" :class="textMutedColor">Company</p>
            <ul class="mt-2 space-y-4">
              <li><NuxtLink class="nav-link" :to="localePath('imprint')">{{$t('footer.imprint')}}</NuxtLink></li>
              <li><NuxtLink class="nav-link" :to="localePath('privacy')">{{ $t('footer.privacy') }}</NuxtLink></li>
              <li><NuxtLink class="nav-link" :to="localePath('terms')">{{ $t('footer.terms') }}</NuxtLink></li>
            </ul>
          </div>
         
        </div>
      </div>

      <div class="flex flex-col-reverse md:flex-row gap-10 row-gap-10 pt-20 pb-20">
        
				<div class="flex-1 flex flex-col gap-y-4">
					<div class="flex items-center space-x-4">
						<!-- Twitter -->
						<a href="https://twitter.com/SmoxyApp" 
							aria-label="Smoxy App twitter profile @SmoxyApp"
							class="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400">
							<svg viewBox="0 0 24 24" alt="twitter icon" fill="currentColor" class="h-5">
							<path d="M13.9027 10.4686L21.3482 2H19.5838L13.119 9.3532L7.95547 2H2L9.8082 13.1193L2 22H3.76443L10.5915 14.2348L16.0445 22H22L13.9023 10.4686H13.9027ZM11.4861 13.2173L10.695 12.1101L4.40018 3.29968H7.11025L12.1902 10.4099L12.9813 11.5172L19.5847 20.7594H16.8746L11.4861 13.2177V13.2173Z" />
							</svg>
						</a>

						<!-- Instagram -->
						<a href="https://www.instagram.com/smoxy.app" 
							aria-label="Smoxy App instagram profile @smoxy.app"
							class="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400">
							<svg viewBox="0 0 30 30" alt="instagram icon" fill="currentColor" class="h-6">
								<circle cx="15" cy="15" r="4"></circle>
								<path
									d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z"
								></path>
						
							</svg>
						</a>
					</div>
					<a :href="sitemapLink" class="text-gray-500" >Sitemap</a> 
					<p class="pt-4 text-sm col-span-3 text-gray-500 dark:text-white/30 ">
          © {{year}} Offlinefirst, LTD.
					</p>
			
				</div>
			

			

         <!-- <div class="flex flex-row ml-auto gap-8 mt-4 space-x-4 sm:mt-0 max-w-screen-md">
            <a href="/imprint" class="nav-link text-sm">{{$t('footer.imprint')}}</a>
            <a href="/privacy" class="nav-link text-sm">{{$t('footer.privacy')}}</a>
            <a href="/terms" class="nav-link text-sm">{{$t('footer.terms')}}</a>
          </div> -->

        <div class="ml-0 mb-10 sm:mb-0 sm:ml-auto mt-4 sm:mt-auto">

				

					<!-- <NuxtLink class="text-gray-500 cursor-pointer" :to="localePath('sitemap')">Sitemap</NuxtLink> -->

					

					<div class="flex items-center space-x-4">
						

          <!-- Language Switcher -->
<!-- 				
           <form>
            <select id="locale-select" 
              v-model="$i18n.locale" 
             
              class="bg-gray-100 py-1 px-1 rounded-sm mr-20">
              <option value="en">English</option>
              <option value="de">Deutsch</option>
            </select>
          </form>   -->

				
					<!-- <NuxtLink  v-for="loc in availableLocales">{{ loc.name }}</NuxtLink> -->
					<NuxtLink :class="{'text-black/30 dark:text-white/30': locale === 'de'}" 
						:to="enSlug ?? switchLocalePath('en')">English</NuxtLink>
  				<NuxtLink class="pr-20" :class="{'text-black/30 dark:text-white/30': locale === 'en'}" 
						:to="deSlug ?? switchLocalePath('de')">Deutsch</NuxtLink> 

					</div>
        </div>
				
      </div>
    </div>
  </div>
</template>